import Moment from "moment";
import "moment/locale/ru";
import priciseHumanizer from "humanize-duration";
import { extendMoment } from "moment-range";

Moment.updateLocale("ru", {
	monthsShort: {
		// по CLDR именно "июл." и "июн.", но какой смысл менять букву на точку ?
		format: "янв._фев._мар._апр._май._июня_июля_авг._сен._окт._ноя._дек.".split("_"),
		standalone: "янв._фев._март_апр._май_июнь_июль_авг._сен._окт._ноя._дек.".split("_")
	}
});
Moment.locale("ru");

Moment.priciseHumanizer = priciseHumanizer.humanizer({units: ["y", "mo", "d"], language: "ru", round: true});

const moment = extendMoment(Moment);

window.moment = moment;

export default moment;
